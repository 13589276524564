<template>
  <div>
    <WsMain>
      <WsReadSection
        :id="_id"
        :modelName="modelName"
        :label="label"
        :fields="fields"
        :leftFields="leftFields"
        :rightFields="rightFields"
        v-model="modelData.area"
      ></WsReadSection>
    </WsMain>
    <WsIndexBelongsToMany
      :belongsToManyModelName="$store.state.stone_model.shop_product.modelName"
      :belongsToManyFields="$store.state.stone_model.shop_product.fields"
      :belongsToManyShowFields="belongsToMany.showFields"
      :belongsToManyPickShowFields="belongsToMany.pickShowFields"
      :belongsToManyPickFilterSelects="_belongsToManyPickFilterSelects"
      :modelName="$store.state.stone_model.shop_subclass.modelName"
      :id="$route.params.id"
      paramsKey="shop_subclasses"
      updateBtnLabel="管理商品"
      label="商品"
    ></WsIndexBelongsToMany>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_subclass";
export default {
  data: () => ({
    belongsToMany: {
      showFields: ["no", "name", "spec"],
      pickShowFields: ["no", "name", "spec", "is_active", "price"],
    },
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: ["name"],
    rightFields: ["id", "sequence"],
    modelData: {
      area: null,
    },
    showFields: {
      shop_subclass: ["id", "name", "is_active"],
    },
  }),

  computed: {
    _belongsToManyPickFilterSelects() {
      const _belongsToManyPickFilterSelects = {};
      _belongsToManyPickFilterSelects.featured_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.featured_classes;
      _belongsToManyPickFilterSelects.shop_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_classes;
      _belongsToManyPickFilterSelects.shop_subclasses =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_subclasses;
      _belongsToManyPickFilterSelects.is_active =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.is_active;
      return _belongsToManyPickFilterSelects;
    },
    _params() {
      return {
        area: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>